<template>
  <div class="shipping-day-percent">
    <p
      v-if="desc"
      class="shipping-day-percent__desc"
      v-html="desc"
    ></p>
    <div
      v-if="percentList.length"
      class="shipping-day-percent__list"
      :class="{ 'shipping-day-percent__scroll': needScroll }"
    >
      <div>
        <p
          v-for="percentItem in percentList"
          :key="`${ percentItem.day }-${ percentItem.percent }`"
          class="shipping-day-percent__list-item"
        >
          {{ formatDayPercent({ type: 'day', data: percentItem }) }}
        </p>
      </div>
      <div class="shipping-day-percent__list-progress">
        <div
          v-for="percentItem in percentList"
          :key="`${ percentItem.day }-${ percentItem.percent }`"
          class="shipping-day-percent__list-progress-content"
        >
          <div class="shipping-day-percent__list-bar">
            <i
              class="shipping-day-percent__list-bar-fill"
              :style="{ width: `${ percentItem.percent || 0 }%` }"
            ></i>
          </div>
        </div>
      </div>
      <div>
        <p
          v-for="percentItem in percentList"
          :key="`${ percentItem.day }-${ percentItem.percent }`"
          class="shipping-day-percent__list-percent shipping-day-percent__list-item"
        >
          {{ formatDayPercent({ type: 'percent', data: percentItem }) }}
        </p>
      </div>
      <div
        v-if="needScroll && percentList.length >= 12"
        class="shipping-day-percent__mask"
      ></div>
    </div>
  </div>
</template>
<script>
import { isArray } from '@shein/common-function'
export default {
  props: {
    item: {
      type: Object,
      default() {
        return {}
      }
    },
    language: {
      type: Object,
      default() {
        return {}
      }
    },
    source: {
      type: String,
      default: ''
    },
    needScroll: {
      type: Boolean,
      default: false
    },
    largeShipStatus: {
      type: Number,
      default: 0
    },
  },
  computed: {
    desc() {
      if (this.source == 'order' || this.source == 'track') return this.item?.desc || ''
      const { desc_type = '', day_percent_desc = '', delivery_day_percent_desc = '', shipping_day_percent_desc = '' } = this.item || {}
      if(this.largeShipStatus && day_percent_desc){
        return day_percent_desc
      }
      return Number(desc_type) === 1 ? delivery_day_percent_desc : shipping_day_percent_desc
    },
    percentList() {
      const { day_percents = [] } = this.item || {}
      if (!isArray(day_percents)) {
        return []
      }
      
      return day_percents
    }
  },
  methods: {
    formatDayPercent({ type = 'day', data = {} }) {
      const {
        SHEIN_KEY_PC_24418 = '',
        SHEIN_KEY_PC_24417 = '',
        SHEIN_KEY_PC_24419 = ''
      } = this.language || {}
      const { day, percent } = data || {}
      if (type === 'day') {
        return (Number(day) === 1
          ? SHEIN_KEY_PC_24417
          : SHEIN_KEY_PC_24418
        ).replace('{0}', day)
      }
      return SHEIN_KEY_PC_24419.replace('{0}', percent)
    },
  }
}
</script>
<style lang="less" scoped>
.shipping-day-percent {
  position: relative;
  width: 248px;
  &__desc {
    padding: 8px;
    font-size: 14px;
    text-align: center;
    color: @sui_color_success;
    background: @sui_color_safety_bg;
  }
  
  &__scroll {
    max-height: 265px;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      width: 6px!important;
    }
    &::-webkit-scrollbar-thumb {
        border-radius: 3px;
        background-color: rgba(224,224,224,1);
    }
    &::-webkit-scrollbar-track {
        margin-top: 13px;
        margin-bottom: 13px;
        border-radius: 3px;
        background-color: rgba(247,248,250,1);
    }
  }

  &__list {
    display: flex;
    width: 100%;
    margin-top: 4px;
  }

  &__list-item {
    white-space: nowrap;
    color: @sui_color_gray_dark1;
    height: 22px;
    line-height: 22px;
  }

  &__list-progress {
    flex: 1;
    margin: 0 8px;
  }

  &__list-progress-content {
    height: 22px;
    padding-top: 8px;
    box-sizing: border-box;
  }

  &__list-bar {
    position: relative;
    height: 6px;
    border-radius: 4px;
    background: @sui_color_gray_weak1;
    overflow: hidden;
  }

  &__list-bar-fill {
    position: absolute;
    bottom: 0;
    width: 0;
    height: 100%;
    background: @sui_color_gray_dark1;
  }

  &__list-percent {
    text-align: right;
  }

  &__mask {
    position: absolute;
    bottom: -6px;
    left: 0;
    width: 100%;
    height: 20px;
    z-index: @zindex-hack;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0%,
      #ffffff 46.35%
    );
  }
}
</style>
